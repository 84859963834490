import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../environments/environment';

export const oktaAuth = new OktaAuth({
  issuer: environment.issuer,
  clientId: environment.clientId,
  redirectUri: `${window.location.origin}/implicit/callback`,
  scopes: ['openid'],
  pkce: true,
});
