/* eslint-disable react/jsx-props-no-spreading */
import { Button, ButtonGroup, Modal } from '@nike/eds';
import { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { DesignPropertiesType } from '../../context/PrintListProvider';
import styles from './ImportButton.module.css';
import useHeader from '../../hooks/useHeader';
import usePrintList from '../../hooks/usePrintList';

export function ImportButton() {
  const {
    dispatch,
    initialDesigns,
    importError,
    importErrorMessage,
    importFinished,
    importHeader,
  } = usePrintList();
  const { userEmail } = useHeader();
  const [isImportModalVisible, setIsImportModalVisible] = useState(false);
  const [designFileJsonData, setDesignFileJsonData] = useState();

  const handleDataUpload = async () => {
    try {
      if (designFileJsonData !== undefined) {
        const currDate = new Date();
        const newDesign: DesignPropertiesType = {
          id: designFileJsonData['UID'],
          filename: designFileJsonData['Project']['Name'],
          season: designFileJsonData['Project']['Season'],
          base_color: designFileJsonData['Extruders'][0]['Color'],
          top_color: designFileJsonData['Extruders'][1]['Color'],
          substrate: 'Mid-Sole',
          material: 'IP-003',
          requested_by: userEmail,
          requested_date: currDate,
          status: 'ready',
          gender: (designFileJsonData['CAD']['Size'] as string).slice(0, 1),
          size: (designFileJsonData['CAD']['Size'] as string).slice(1, 3),
        };

        dispatch({ type: 'DESIGN_IMPORTED', payload: [newDesign, ...initialDesigns] });

        setDesignFileJsonData(undefined);
        return true;
      }
      setDesignFileJsonData(undefined);
      return false;
    } catch (error) {
      let errorMessage = '';
      if (error instanceof Error) {
        errorMessage = error.message;
      }
      setDesignFileJsonData(undefined);
      return false;
    }
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.map(async (file: File) => {
        if (file !== undefined) {
          const reader = new FileReader();

          reader.onload = event => {
            const result = event.target?.result;
            if (typeof result === 'string') {
              const data = JSON.parse(result);
              setDesignFileJsonData(data);
            } else {
              dispatch({ type: 'IMPORT_ERROR', payload: '865' });
              console.log('Error: File is not in JSON format.');
            }
          };

          reader.readAsText(file);
        } else {
          setDesignFileJsonData(undefined);
          dispatch({ type: 'IMPORT_ERROR', payload: '865' });
        }
      });
    },

    []
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div className="eds-grid--m-col-1">
      <div className="eds-flex eds-flex--direction-row eds-flex--justify-content-flex-end eds-flex--align-items-center eds-spacing--mr-48">
        <Button
          className="eds-spacing--mt-96 eds-spacing--ml-48"
          onClick={() => {
            dispatch({ type: 'OPEN_IMPORT' });
            setIsImportModalVisible(true);
          }}
        >
          Import
        </Button>
      </div>
      <Modal
        className={`${styles['eds-modal']}`}
        onDismiss={() => {
          setIsImportModalVisible(false);
          dispatch({ type: 'CLOSE_IMPORT_MODAL' });
        }}
        isOpen={isImportModalVisible}
        headerSlot={<div className="eds-spacing--mr-8">{importHeader}</div>}
        footerSlot={
          !importFinished ? (
            <ButtonGroup>
              <Button
                disabled={designFileJsonData === undefined}
                onClick={async () => {
                  if (designFileJsonData !== null) {
                    const dataUploaded: boolean = await handleDataUpload();

                    if (dataUploaded) {
                      setDesignFileJsonData(undefined);
                    } else {
                      setDesignFileJsonData(undefined);
                      dispatch({ type: 'IMPORT_ERROR', payload: '865' });
                    }
                  } else {
                    setDesignFileJsonData(undefined);
                    dispatch({ type: 'IMPORT_ERROR', payload: '865' });
                  }
                }}
                size="small"
              >
                Import
              </Button>
              <Button
                onClick={() => {
                  setIsImportModalVisible(false);
                  dispatch({ type: 'CLOSE_IMPORT_MODAL' });
                }}
                size="small"
                variant="secondary"
              >
                Cancel
              </Button>
            </ButtonGroup>
          ) : (
            <Button
              onClick={() => {
                setIsImportModalVisible(false);
                dispatch({ type: 'CLOSE_IMPORT_MODAL' });
              }}
              size="small"
              variant="primary"
            >
              Ok
            </Button>
          )
        }
      >
        {!importFinished && (
          <div {...getRootProps()}>
            <input className={`${styles['dotted']} eds-spacing--mb-12`} {...getInputProps()} />
            {importError && (
              <p className="eds-color--danger eds-spacing--mb-4">{importErrorMessage}</p>
            )}
            <p>File format: ai, svg, 3DM</p>
          </div>
        )}
      </Modal>
    </div>
  );
}

export default ImportButton;
