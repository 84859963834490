import { Tab, TabGroup, Text } from '@nike/eds';
import PrintList from '../../components/PrintList/PrintList';
import { ImportButton } from '../../components/ImportButton/ImportButton';
import usePrintList from '../../hooks/usePrintList';
import FilterButton from '../../components/FilterSidePanel/FilterSidePanel';
import PrintListSearch from '../../components/PrintListSearch/PrintListSearch';
import { SelectedFilterItems } from '../../components/SelectedFilterItems/SelectedFilterItems';
import Pagination from '../../components/Pagination/Pagination';

import styles from './Home.module.css';

export function Home() {
  const { dispatch, showCompleted, completedSearchedDesigns, searchedDesigns } = usePrintList();

  return (
    <section className="eds-spacing--ml-48">
      <div className="eds-grid eds-grid--m-cols-6">
        {/* First row: Import button */}
        <div className="eds-grid--m-col-5">
          <Text className="eds-spacing--mt-96" font="title-2">
            Print Request
          </Text>
        </div>
        <ImportButton />
        {/* First Row */}
        {/* Second Row: Tabs */}
        <div className="eds-grid--col-full">
          <TabGroup
            name="tab-button-group"
            activeId={showCompleted ? 'completed' : 'new'}
            onChange={e => {
              dispatch({
                type: 'SET_TAB',
                payload: e.target.id === 'completed',
              });
            }}
          >
            <Tab id="new">New</Tab>
            <Tab id="completed">Completed</Tab>
          </TabGroup>
        </div>
        {/* Second Row */}
        {/* Third Row: Search/Filters  */}
        <div className="eds-grid--m-col-5">
          <PrintListSearch />
        </div>
        <div className="eds-grid--m-col-1">
          <FilterButton />
        </div>
        {/* Third Row */}
        {/* Fourth Row: Filters */}
        <SelectedFilterItems />
        {/* Fourth Row */}
        {/* Fifth row: Table */}
        <PrintList />
        {/* Fifth row */}
        <div className="eds-grid--col-full">
          <Pagination designs={showCompleted ? completedSearchedDesigns : searchedDesigns} />
        </div>
      </div>
    </section>
  );
}

export default Home;
