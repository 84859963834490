/* eslint-disable no-restricted-globals */
import { IconButton, TextField, Text } from '@nike/eds';
import { useEffect, useState } from 'react';
import { DesignPropertiesType } from '../../context/PrintListProvider';
import usePrintList from '../../hooks/usePrintList';

import styles from './Pagination.module.css';

type PaginationProps = {
  designs: DesignPropertiesType[];
};

function Pagination({ designs }: PaginationProps) {
  const { dispatch, pageNumber } = usePrintList();
  const [paginationInput, setPaginationInput] = useState(pageNumber);

  useEffect(() => {
    setPaginationInput(pageNumber);
  }, [pageNumber]);

  return (
    <div className="eds-flex eds-flex--justify-content-center eds-flex--align-items-center eds-spacing--mb-48 eds-spacing--mr-48">
      <IconButton
        className="eds-spacing--mr-12 "
        variant="ghost"
        disabled={pageNumber === 1 || Math.ceil(designs.length / 7) === 0}
        onClick={() => dispatch({ type: 'SET_PAGE_NUMBER', payload: pageNumber - 1 })}
        icon="CaretLeft"
        label=""
      >
        Prev
      </IconButton>
      <TextField
        id="page-number"
        className={`${styles['pagination-input']} eds-spacing--mr-12`}
        hideLabel
        onChange={event => {
          if (!isNaN(parseInt(event.target.value, 10))) {
            if (
              parseInt(event.target.value, 10) > 0 &&
              parseInt(event.target.value, 10) <= Math.ceil(designs.length / 7)
            ) {
              setPaginationInput(parseInt(event.target.value, 10));
            }
          }
        }}
        onKeyDown={e => {
          if (e.key === 'Enter') {
            if (paginationInput !== pageNumber) {
              dispatch({ type: 'SET_PAGE_NUMBER', payload: paginationInput });
            }
          }
        }}
        label=""
        maxLength={Math.ceil(designs.length / 7).toString().length}
        placeholder={pageNumber.toString()}
      />
      <Text font="title-6">{`of ${Math.ceil(designs.length / 7)}`}</Text>
      <IconButton
        className="eds-spacing--ml-12 "
        variant="ghost"
        disabled={
          pageNumber === Math.ceil(designs.length / 7) || Math.ceil(designs.length / 7) === 0
        }
        onClick={() => dispatch({ type: 'SET_PAGE_NUMBER', payload: pageNumber + 1 })}
        icon="CaretRight"
        label=""
      >
        Next
      </IconButton>
    </div>
  );
}

export default Pagination;
