/* eslint-disable no-nested-ternary */
import { Box, Button, ButtonGroup, Icon, IconButton, Modal, Text } from '@nike/eds';
import { useState } from 'react';
import styles from './PrintButton.module.css';
import { DesignPropertiesType } from '../../context/PrintListProvider';
import usePrintList from '../../hooks/usePrintList';

interface PrintButtonProps {
  design: DesignPropertiesType;
}

export function PrintButton({ design }: PrintButtonProps) {
  const {
    dispatch,
    completedSearchedDesigns,
    printButtonText,
    printError,
    printHeader,
    printStep,
    printMessage,
  } = usePrintList();
  const [isPrintModalVisible, setIsPrintModalVisible] = useState(false);

  return (
    <div className="eds-grid--m-col-1">
      <IconButton
        name="Print"
        size="medium"
        variant="ghost"
        onClick={() => {
          setIsPrintModalVisible(true);
          dispatch({ type: 'OPEN_PRINT' });
        }}
        icon="Print"
        label=""
      />

      <Modal
        className={`${styles['eds-modal']}`}
        onDismiss={() => {
          setIsPrintModalVisible(false);
          dispatch({ type: 'CLOSE_PRINT_MODAL' });
        }}
        isOpen={isPrintModalVisible}
        headerSlot={
          <div>
            <Text>{printHeader}</Text>
          </div>
        }
        footerSlot={
          <ButtonGroup>
            <Button
              onClick={async () => {
                if (printStep === 1) {
                  // scan function here
                  dispatch({ type: 'SCAN' });
                } else if (printStep === 2) {
                  // send print job
                  if (!printError) {
                    dispatch({
                      type: 'PRINT_SENT',
                      payload: [...completedSearchedDesigns, design],
                    });
                  } else {
                    dispatch({ type: 'PRINT_ERROR', payload: '865' });
                  }
                } else {
                  setIsPrintModalVisible(false);
                  dispatch({
                    type: 'CLOSE_PRINT_MODAL',
                  });
                }
              }}
              size="small"
            >
              {printButtonText}
            </Button>
            {printStep !== 3 && (
              <Button
                onClick={() => {
                  setIsPrintModalVisible(false);
                  dispatch({
                    type: 'CLOSE_PRINT_MODAL',
                  });
                }}
                size="small"
                variant="secondary"
              >
                Cancel
              </Button>
            )}
          </ButtonGroup>
        }
      >
        <div
          className={`${styles['modal-content-wrapper']} ${
            printStep === 3 && printError ? 'eds-color--danger' : ''
          } eds-flex eds-flex--direction-row eds-background--secondary eds-spacing--p-24 eds-spacing--mb-24`}
        >
          <Box className="eds-spacing--mr-8" height="24px" width="24px">
            {printStep === 3 && printError ? (
              <Icon className="eds-color--danger" name="AlertCircle" size="m" />
            ) : (
              <Icon name="CheckCircle" size="m" />
            )}
          </Box>
          <div className="eds-flex eds-flex--direction-column">
            <span className={`${styles['print-steps-wrapper']}`}>
              {printStep <= 2 && `${printStep} of 2`}
            </span>
            <Text className={`${styles['print-message-wrapper']}`} font="body-2">
              {printMessage}
            </Text>
          </div>
        </div>
        <Text className="eds-spacing--mb-12" font="body-2">
          {design.filename}
        </Text>
        <Text className="eds-spacing--mb-12" font="body-2">
          {design.id}
        </Text>
      </Modal>
    </div>
  );
}

export default PrintButton;
