/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo, useReducer, createContext, ReactElement } from 'react';

export type HeaderDropdownItems = {
  label: string;
  value: string;
};

type HeaderStateType = {
  userEmail: string;
  machine: HeaderDropdownItems;
  location: HeaderDropdownItems;
  robotConnected: boolean;
  scannerConnected: boolean;
};

const initHeaderState: HeaderStateType = {
  userEmail: '',
  machine: { label: 'Cell', value: 'cell' },
  location: { label: 'APCC', value: 'apcc' },
  robotConnected: false,
  scannerConnected: false,
};

export const REDUCER_ACTION_TYPE = {
  SET_USEREMAIL: 'SET_USEREMAIL',
  SET_MACHINE: 'SET_MACHINE',
  SET_LOCATION: 'SET_LOCATION',
  SET_ROBOT_CONNECTED: 'SET_ROBOT_CONNECTED',
  SET_SCANNER_CONNECTED: 'SET_SCANNER_CONNECTED',
};

export type ReducerActionType = typeof REDUCER_ACTION_TYPE;

export type ReducerAction =
  | { type: 'SET_USEREMAIL'; payload: string }
  | { type: 'SET_MACHINE'; payload: HeaderDropdownItems }
  | { type: 'SET_LOCATION'; payload: HeaderDropdownItems }
  | { type: 'SET_ROBOT_CONNECTED'; payload: boolean }
  | { type: 'SET_SCANNER_CONNECTED'; payload: boolean };

const reducer = (state: HeaderStateType, action: ReducerAction): HeaderStateType => {
  switch (action.type) {
    case 'SET_USEREMAIL': {
      if (!action.payload) {
        throw new Error('action.payload missing in SET_USEREMAIL action');
      }
      return { ...state, userEmail: action.payload };
    }
    case 'SET_MACHINE': {
      return { ...state, machine: action.payload };
    }
    case 'SET_LOCATION': {
      if (!action.payload) {
        throw new Error('action.payload missing in SET_LOCATION action');
      }
      return { ...state, location: action.payload };
    }
    case 'SET_ROBOT_CONNECTED': {
      return { ...state, robotConnected: action.payload };
    }
    case 'SET_SCANNER_CONNECTED': {
      return { ...state, scannerConnected: action.payload };
    }

    default:
      throw new Error('Unidentified reducer action type');
  }
};

const useHeaderContext = (initHeaderState: HeaderStateType) => {
  const [state, dispatch] = useReducer(reducer, initHeaderState);

  const HEADER_REDUCER_ACTIONS = useMemo(() => REDUCER_ACTION_TYPE, []);

  const { userEmail, machine, location, robotConnected, scannerConnected } = state;

  return {
    dispatch,
    HEADER_REDUCER_ACTIONS,
    userEmail,
    machine,
    location,
    robotConnected,
    scannerConnected,
  };
};

export type UseHeaderContextType = ReturnType<typeof useHeaderContext>;

const initHeaderContextState: UseHeaderContextType = {
  dispatch: () => {},
  HEADER_REDUCER_ACTIONS: REDUCER_ACTION_TYPE,
  userEmail: '',
  machine: { label: 'Cell', value: 'cell' },
  location: { label: 'APCC', value: 'apcc' },
  robotConnected: false,
  scannerConnected: false,
};

const HeaderContext = createContext<UseHeaderContextType>(initHeaderContextState);

type ChildrenType = { children?: ReactElement | ReactElement[] };

export function HeaderProvider({ children }: ChildrenType): ReactElement {
  return (
    <HeaderContext.Provider value={useHeaderContext(initHeaderState)}>
      {children}
    </HeaderContext.Provider>
  );
}

export default HeaderContext;
