import { StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { PrintListProvider } from './context/PrintListProvider';

import '@nike/eds/dist/index.css';

import App from './app/app';
import { HeaderProvider } from './context/HeaderProvider';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <BrowserRouter>
      <HeaderProvider>
        <PrintListProvider>
          <App />
        </PrintListProvider>
      </HeaderProvider>
    </BrowserRouter>
  </StrictMode>
);
