import { Icon, IconButton, TextField } from '@nike/eds';
import { useEffect, useState } from 'react';
import usePrintList from '../../hooks/usePrintList';
import styles from './PrintListSearch.module.css';

export function PrintListSearch() {
  const { dispatch, selectedFilters, searchQuery } = usePrintList();
  const [textValue, setTextValue] = useState('');

  useEffect(() => {
    setTextValue(searchQuery);
  }, [searchQuery]);

  return (
    <TextField
      className={`${styles['list-search']}`}
      type="text"
      value={textValue}
      id="search"
      hasErrors={false}
      beforeSlot={<Icon name="Search" />}
      afterSlot={
        textValue.length > 0 ? (
          <IconButton
            icon="Close"
            label=""
            size="small"
            variant="ghost"
            onClick={() => {
              setTextValue('');
              dispatch({ type: 'SET_SELECTED_FILTERS', payload: selectedFilters });
            }}
          />
        ) : undefined
      }
      placeholder="Search"
      label=""
      onChange={e => {
        setTextValue(e.target.value);

        if (e.target.value.length <= 0) {
          dispatch({ type: 'SET_SELECTED_FILTERS', payload: selectedFilters });
        } else {
          dispatch({ type: 'SEARCH', payload: e.target.value });
        }
      }}
      //   onKeyDown={e => {
      // if (e.key === 'Enter') {
      //   dispatch({ type: 'SEARCH', payload: textValue });
      // }
      //   }}
    />
  );
}

export default PrintListSearch;
