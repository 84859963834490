import { useNavigate } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { toRelativeUrl } from '@okta/okta-auth-js';
import { oktaAuth } from '../utils/oktaAuth';
import AppRoutes from '../components/Routes';
import { VerticalNav } from '../components/VerticalNav/VerticalNav';
import { Header } from '../components/Header/Header';
import styles from './app.module.css';
import '@nike/eds/dist/index.css';

export function App() {
  const navigate = useNavigate();
  const restoreOriginalUri = (_oktaAuth: any, originalUri: string) => {
    navigate(toRelativeUrl(originalUri || '/', window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <div className={`${styles['app-wrapper']}`}>
        <VerticalNav />
        <main className={`${styles['app-body']}`}>
          <Header />
          <AppRoutes />
        </main>
      </div>
    </Security>
  );
}

export default App;
