/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable camelcase */
import { useState } from 'react';
import { VerticalNavbar, Icon } from '@nike/eds';
import { Link } from 'react-router-dom';
import { useOktaAuth } from '@okta/okta-react';
import styles from './VerticalNav.module.css';
import usePrintList from '../../hooks/usePrintList';

export function VerticalNav() {
  const { dispatch, activeNavItem } = usePrintList();
  const { authState } = useOktaAuth();

  const onNavigate = e => {
    dispatch({ type: 'SET_NAV_ITEM', payload: e.id });
  };

  const items = [
    {
      id: '/',
      active: activeNavItem === '/',
      icon: 'Shoe',
      label: 'Home',
    },
  ];

  return (
    <div className={`${styles['vertical-nav-wrapper']}`}>
      {authState?.isAuthenticated ? (
        <VerticalNavbar
          appName="Make Experience"
          items={items}
          onNavigate={onNavigate}
          navLogoSlot={
            <Link to="/">
              <Icon name="NikeSwoosh" size="l" className="nike-shoe-icon" />
            </Link>
          }
          panelLogoSlot={
            <img alt="Placeholder Logo" src="https://via.placeholder.com/48x48?text=Logo" />
          }
        />
      ) : (
        <VerticalNavbar
          appName="Make Experience"
          items={[]}
          onNavigate={() => {}}
          navLogoSlot={
            <Link to="/">
              <Icon name="NikeSwoosh" size="l" className="nike-shoe-icon" />
            </Link>
          }
          panelLogoSlot={
            <img alt="Placeholder Logo" src="https://via.placeholder.com/48x48?text=Logo" />
          }
        />
      )}
    </div>
  );
}

export default VerticalNav;
