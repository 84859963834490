/* eslint-disable camelcase */
import { AppHeader, Avatar, Text } from '@nike/eds';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect } from 'react';
import jwt_decode from 'jwt-decode';
import { MyToken } from '../../interfaces';
import useHeader from '../../hooks/useHeader';

import styles from './Header.module.css';

export function Header() {
  const { dispatch, userEmail } = useHeader();
  const { authState } = useOktaAuth();

  function getFirstLettersFromEmail(str: string): string {
    const firstLetter = str.charAt(0);
    let secondLetter = '';
    const index = str.indexOf('.');
    if (index !== -1) {
      secondLetter = str.charAt(index + 1);
    }
    return firstLetter + secondLetter;
  }

  useEffect(() => {
    if (authState?.isAuthenticated) {
      if (authState !== undefined && authState.accessToken !== undefined) {
        const decoded = jwt_decode<MyToken>(authState.accessToken.accessToken);
        if (userEmail !== decoded.sub) {
          dispatch({ type: 'SET_USEREMAIL', payload: decoded.sub });
        }
      }
    } else {
      // TODO: Access denied page maybe?
      console.log('User is unauthenticated');
    }
  }, [authState, dispatch]);

  if (authState?.isAuthenticated) {
    return (
      <AppHeader
        appName="Make Experience"
        avatarSlot={
          <div className="eds-flex eds-flex--direction-row eds-flex--align-items-center">
            <Avatar initials={getFirstLettersFromEmail(userEmail)} />
          </div>
        }
        commandBarSlot={
          <div className="eds-flex eds-flex--direction-row">
            <Text className="eds-color--secondary eds-spacing--mr-4" font="subtitle-2">
              Location:
            </Text>
            <Text className="eds-spacing--mr-12" font="subtitle-2">
              APCC
            </Text>
            <Text className="eds-color--secondary eds-spacing--mr-4" font="subtitle-2">
              Cell:
            </Text>
            <Text font="subtitle-2">Nectar</Text>
          </div>
        }
      />
    );
  }

  return <AppHeader appName="The Make Experience" />;
}
