import { LoginCallback } from '@okta/okta-react';
import { Routes, Route } from 'react-router-dom';
import { RequiresAuth } from './requires-auth';

export interface RouteConfig {
  path: string;
  element: React.ReactElement;
}

interface AuthGuardProps {
  redirectUri: string;
  // needs to be self closing <Loading />
  loadingElement: React.ReactElement;
  routes: RouteConfig[];
}

export function AuthGuard({ redirectUri, loadingElement, routes }: AuthGuardProps) {
  return (
    <Routes>
      {routes.map(x => (
        <Route
          key={x.path}
          path={x.path}
          element={<RequiresAuth loadingElement={loadingElement} />}
        >
          <Route path="" element={x.element} />
        </Route>
      ))}
      <Route
        key={redirectUri}
        path={redirectUri}
        element={<LoginCallback loadingElement={loadingElement} />}
      />
    </Routes>
  );
}

export default AuthGuard;
