/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-empty-function */
import { useMemo, useReducer, createContext, ReactElement } from 'react';

export type DesignFileType = {
  uid: string;
  project_name: string;
  base_color: string;
  top_color: string;
  substrate: string;
  season: string;
  gender: string;
  size: string;
};

export type DesignPropertiesType = {
  id: string;
  filename: string;
  season: string;
  base_color: string;
  top_color: string;
  gender: string;
  size: string;
  substrate: string;
  material: string;
  requested_by: string;
  requested_date: Date;
  status: string;
  // filepath: string;
};

export type FiltersType = {
  id?: string[];
  filename?: string[];
  base_color?: string[];
  top_color?: string[];
  substrate?: string[];
  requested_by?: string[];
  requested_date?: Date[];
  status?: string[];
  filepath?: string[];
};

type PrintListStateType = {
  activeNavItem: string;
  sortOn:
    | 'filename'
    | 'base_color'
    | 'top_color'
    | 'gender'
    | 'size'
    | 'substrate'
    | 'requested_by'
    | 'date_time';
  sortDirection: boolean;
  activeId: string;
  designFileJsonData: object;
  importFinished: boolean;
  importError: boolean;
  importErrorMessage: string;
  importHeader: string;
  importButtonText: string;
  printHeader: string;
  printMessage: string;
  printButtonText: string;
  printStep: number;
  printError: boolean;
  showCompleted: boolean;
  pageNumber: number;
  searchQuery: string;
  filters: FiltersType;
  selectedFilters: FiltersType;
  initialDesigns: DesignPropertiesType[];
  filteredDesigns: DesignPropertiesType[];
  searchedDesigns: DesignPropertiesType[];
  initialCompletedDesigns: DesignPropertiesType[];
  completedFilteredDesigns: DesignPropertiesType[];
  completedSearchedDesigns: DesignPropertiesType[];
};

type SortPrintListType = {
  sortOn:
    | 'filename'
    | 'base_color'
    | 'top_color'
    | 'gender'
    | 'size'
    | 'substrate'
    | 'requested_by'
    | 'date_time';
  direction: boolean;
};

const initPrintListState: PrintListStateType = {
  activeNavItem: '/',
  sortOn: 'date_time',
  sortDirection: false,
  activeId: 'new',
  designFileJsonData: {},
  importFinished: false,
  importError: false,
  importErrorMessage: '',
  importHeader: '',
  importButtonText: '',
  printHeader: '',
  printMessage: '',
  printButtonText: '',
  printStep: 1,
  printError: false,
  showCompleted: false,
  pageNumber: 1,
  filters: {},
  searchQuery: '',
  selectedFilters: {},
  initialDesigns: [],
  filteredDesigns: [],
  searchedDesigns: [],
  initialCompletedDesigns: [],
  completedFilteredDesigns: [],
  completedSearchedDesigns: [],
};

export const REDUCER_ACTION_TYPE = {
  SET: 'SET',
  SORT: 'SORT',
  SEARCH: 'SEARCH',
  RESET: 'RESET',
};

export type ReducerActionType = typeof REDUCER_ACTION_TYPE;

export type ReducerAction =
  | { type: 'SET_NAV_ITEM'; payload: string }
  | { type: 'OPEN_IMPORT' }
  | { type: 'DESIGN_IMPORTED'; payload: DesignPropertiesType[] }
  | { type: 'IMPORT_ERROR'; payload: string }
  | { type: 'CLOSE_IMPORT_MODAL' }
  | { type: 'OPEN_PRINT' }
  | { type: 'SCAN' }
  | { type: 'PRINT_SENT'; payload: DesignPropertiesType[] }
  | { type: 'PRINT_ERROR'; payload: string }
  | { type: 'CLOSE_PRINT_MODAL' }
  | { type: 'SET_TAB'; payload: boolean }
  | { type: 'SET_PAGE_NUMBER'; payload: number }
  | { type: 'SET'; payload: DesignPropertiesType[] }
  | { type: 'SORT'; payload: SortPrintListType }
  | { type: 'SEARCH'; payload: string }
  | { type: 'SET_COMPLETED'; payload: DesignPropertiesType[] }
  | { type: 'SORT_COMPLETED'; payload: SortPrintListType }
  | { type: 'SEARCH_COMPLETED'; payload: string }
  | { type: 'RESET_COMPLETED' }
  | { type: 'SET_FILTERS'; payload: FiltersType }
  | { type: 'SET_SELECTED_FILTERS'; payload: FiltersType }
  | { type: 'RESET' };

const printFlow = (state: PrintListStateType, action: ReducerAction): PrintListStateType => {
  switch (action.type) {
    case 'SET_NAV_ITEM': {
      return {
        ...state,
        activeNavItem: action.payload,
      };
    }
    case 'OPEN_IMPORT': {
      return {
        ...state,
        importHeader: 'Import',
        importButtonText: 'Import',
        importFinished: false,
        importError: false,
      };
    }
    case 'DESIGN_IMPORTED': {
      return {
        ...state,
        importHeader: 'Import Successful',
        importButtonText: 'OK',
        importFinished: true,
        importError: false,
        searchQuery: '',
        selectedFilters: {},
        initialDesigns: action.payload,
        filteredDesigns: action.payload,
        searchedDesigns: action.payload,
      };
    }
    case 'IMPORT_ERROR': {
      return {
        ...state,
        importHeader: 'Import',
        importErrorMessage: `File could not be imported. Error ${action.payload}`,
        importButtonText: 'OK',
        importError: true,
      };
    }
    case 'CLOSE_IMPORT_MODAL': {
      return {
        ...state,
        importFinished: false,
        importError: false,
        designFileJsonData: {},
        importErrorMessage: '',
        importHeader: '',
        importButtonText: '',
      };
    }
    case 'OPEN_PRINT': {
      return {
        ...state,
        printHeader: 'Print Setup',
        printMessage: 'Make sure the machine is on, calibrated, and the substrate is set up.',
        printButtonText: 'Next',
        printStep: 1,
        printError: false,
      };
    }
    case 'SCAN': {
      return {
        ...state,
        printHeader: 'Scan & Verify',
        printMessage: 'Review your scan and verify it can be printed.',
        printButtonText: 'Print',
        printStep: 2,
      };
    }
    case 'PRINT_SENT': {
      return {
        ...state,
        printHeader: 'Print Sent',
        printMessage: 'Your print has been sent.',
        printButtonText: 'OK',
        printStep: 3,
        initialCompletedDesigns: action.payload,
        completedFilteredDesigns: action.payload,
        completedSearchedDesigns: action.payload,
      };
    }
    case 'PRINT_ERROR': {
      return {
        ...state,
        printHeader: 'Error',
        printMessage: `Print could not be completed. Error ${action.payload}`,
        printButtonText: 'OK',
        printStep: 3,
        printError: true,
      };
    }
    case 'CLOSE_PRINT_MODAL': {
      return {
        ...state,
        printError: false,
        printHeader: '',
        printMessage: '',
        printButtonText: '',
        printStep: 1,
      };
    }
    /**
     *This action is used to set the state of the "showCompleted" property in the reducer.
     *@type {string} 'SET_TAB' - The type of the action
     *@param {boolean} payload - The new value of the "showCompleted" property in the state
     *@returns {Object} - The updated state object with the new "showCompleted" value
     */
    case 'SET_TAB': {
      return { ...state, showCompleted: action.payload };
    }

    /**
     *This action is used to set the page number of the print list.
     *@type {string} 'SET_PAGE_NUMBER' - the type of action.
     *@param {number} action.payload - The new page number to set in the state.
     *@returns {Object} - A new state object with the updated page number.
     */
    case 'SET_PAGE_NUMBER': {
      return { ...state, pageNumber: action.payload };
    }

    /**
     *This action is used to set the filters for the designs.
     *@typedef {string} 'SET_FILTERS' - The type of action.
     *@param {FiltersType} action.payload - The new filters to be set in the state.
     *@returns {PrintListStateType} - A new state object with the updated filters.
     */
    case 'SET_FILTERS': {
      return { ...state, filters: action.payload };
    }

    /**
     *This function updates the state when the action type is SET_SELECTED_FILTERS.
     *It filters the designs based on the selected filters and updates the state accordingly.
     *@type {string} 'SET_SELECTED_FILTERS' - The type of action.
     *@param {FiltersType} action.payload - The selected filters to be applied to the designs.
     *@returns {PrintListStateType} - A new state object with the updated selectedFilters, filteredDesigns, and searchedDesigns.
     */
    case 'SET_SELECTED_FILTERS': {
      if (
        Object.keys(action.payload).length === 0 ||
        (action.payload.base_color?.length === 0 &&
          action.payload.top_color?.length === 0 &&
          action.payload.substrate?.length === 0)
      ) {
        return {
          ...state,
          searchQuery: '',
          selectedFilters: action.payload,
          filteredDesigns: state.initialDesigns,
          searchedDesigns: state.initialDesigns,
          completedFilteredDesigns: state.initialCompletedDesigns,
          completedSearchedDesigns: state.initialCompletedDesigns,
        };
      }

      const completedFilteredBaseColor: DesignPropertiesType[] =
        state.initialCompletedDesigns.filter(designs =>
          action.payload.base_color?.includes(designs.base_color)
        );
      const completedFilteredTopColor: DesignPropertiesType[] =
        state.initialCompletedDesigns.filter(designs =>
          action.payload.top_color?.includes(designs.top_color)
        );
      const completedFilteredType: DesignPropertiesType[] = state.initialCompletedDesigns.filter(
        designs => action.payload.substrate?.includes(designs.substrate)
      );
      // const filteredMaterial: DesignPropertiesType[] = state.completedDesigns.filter(
      //   designs => action.payload.base_color?.includes(designs.base_color)
      // );
      const combinedCompletedFilteredPrintList = [
        ...new Set([
          ...completedFilteredBaseColor,
          ...completedFilteredTopColor,
          ...completedFilteredType,
        ]),
      ];

      const filteredBaseColor: DesignPropertiesType[] = state.initialDesigns.filter(designs =>
        action.payload.base_color?.includes(designs.base_color)
      );
      const filteredTopColor: DesignPropertiesType[] = state.initialDesigns.filter(designs =>
        action.payload.top_color?.includes(designs.top_color)
      );
      const filteredType: DesignPropertiesType[] = state.initialDesigns.filter(designs =>
        action.payload.substrate?.includes(designs.substrate)
      );
      // const filteredMaterial: DesignPropertiesType[] = state.completedDesigns.filter(
      //   designs => action.payload.base_color?.includes(designs.base_color)
      // );
      const combinedFilteredPrintList = [
        ...new Set([...filteredBaseColor, ...filteredTopColor, ...filteredType]),
      ];
      return {
        ...state,
        selectedFilters: action.payload,
        filteredDesigns: combinedFilteredPrintList,
        searchedDesigns: combinedFilteredPrintList,
        completedFilteredDesigns: combinedCompletedFilteredPrintList,
        completedSearchedDesigns: combinedCompletedFilteredPrintList,
      };
    }

    /**
     *This SET_COMPLETED action is used to update the state with the completed design files.
     *@type {string} 'SET_COMPLETED' - The type of action.
     *@param {DesignPropertiesType[]} action.payload - The array of completed design file objects.
     *@throws {Error} - If action.payload is missing.
     *@returns {Object} - A new state object with updated completed design file data.
     */
    case 'SET_COMPLETED': {
      if (!action.payload) {
        throw new Error('action.payload missing in SET action');
      }
      return {
        ...state,
        initialCompletedDesigns: action.payload,
        completedFilteredDesigns: action.payload,
        completedSearchedDesigns: action.payload,
      };
    }

    /**
     *The SET action is used to set the initial designs, filtered designs, and searched designs in the state.
     *@type {string} 'SET' - the type of action.
     *@param {DesignPropertiesType[]} action.payload - The new design data to set in the state.
     *@throws {Error} If action.payload is missing, an error will be thrown.
     *@returns {Object} - A new state object with the updated initialDesigns, filteredDesigns, and searchedDesigns.
     */
    case 'SET': {
      if (!action.payload) {
        throw new Error('action.payload missing in SET action');
      }
      return {
        ...state,
        initialDesigns: action.payload,
        filteredDesigns: action.payload,
        searchedDesigns: action.payload,
      };
    }

    /**
     *This SORT action is used to sort the list of designs and the completed designs based on a specific property.
     *@type {string} 'SORT' - the type of action.
     * @param {boolean} direction - The direction to sort the array in.
     *@returns {Object} - A new state object with the sorted searchedDesigns and completedSearchedDesigns lists.
     */
    case 'SORT': {
      if (action.payload.direction) {
        if (action.payload.sortOn === 'date_time') {
          const sortedList: DesignPropertiesType[] = state.searchedDesigns.sort(
            (a, b) => a.requested_date.getTime() - b.requested_date.getTime()
          );
          const sortedCompletedList: DesignPropertiesType[] = state.completedSearchedDesigns.sort(
            (a, b) => a.requested_date.getTime() - b.requested_date.getTime()
          );

          return {
            ...state,
            sortDirection: action.payload.direction,
            sortOn: action.payload.sortOn,
            searchedDesigns: sortedList,
            completedSearchedDesigns: sortedCompletedList,
          };
        }

        const sortedList: DesignPropertiesType[] = state.searchedDesigns.sort((a, b) =>
          a[`${action.payload.sortOn}`] < b[`${action.payload.sortOn}`] ? -1 : 1
        );
        const sortedCompletedList: DesignPropertiesType[] = state.completedSearchedDesigns.sort(
          (a, b) => (a[`${action.payload.sortOn}`] < b[`${action.payload.sortOn}`] ? -1 : 1)
        );

        return {
          ...state,
          sortDirection: action.payload.direction,
          sortOn: action.payload.sortOn,
          searchedDesigns: sortedList,
          completedSearchedDesigns: sortedCompletedList,
        };
      }

      if (action.payload.sortOn === 'date_time') {
        const sortedList: DesignPropertiesType[] = state.searchedDesigns.sort(
          (a, b) => b.requested_date.getTime() - a.requested_date.getTime()
        );
        const sortedCompletedList: DesignPropertiesType[] = state.completedSearchedDesigns.sort(
          (a, b) => b.requested_date.getTime() - a.requested_date.getTime()
        );

        return {
          ...state,
          sortDirection: action.payload.direction,
          sortOn: action.payload.sortOn,
          searchedDesigns: sortedList,
          completedSearchedDesigns: sortedCompletedList,
        };
      }

      const sortedList: DesignPropertiesType[] = state.searchedDesigns.sort((a, b) =>
        a[`${action.payload.sortOn}`] > b[`${action.payload.sortOn}`] ? -1 : 1
      );
      const sortedCompletedList: DesignPropertiesType[] = state.completedSearchedDesigns.sort(
        (a, b) => (a[`${action.payload.sortOn}`] > b[`${action.payload.sortOn}`] ? -1 : 1)
      );

      return {
        ...state,
        sortDirection: action.payload.direction,
        sortOn: action.payload.sortOn,
        searchedDesigns: sortedList,
        completedSearchedDesigns: sortedCompletedList,
      };
    }
    /**
     *The SEARCH action is used to filter the print list based on the search query.
     *@type {string} 'SEARCH' - the type of action.
     *@param {string} action.payload - The string to filter the designs by
     *@returns {Object} - A new state object with the updated search query, filtered designs, and completed filtered designs.
     */
    case 'SEARCH': {
      const completedSearchedPrintList: DesignPropertiesType[] =
        state.completedFilteredDesigns.filter(
          designs =>
            designs.filename.toLowerCase().includes(action.payload.toLowerCase()) ||
            designs.requested_by.toLowerCase().includes(action.payload.toLowerCase()) ||
            designs.status.toLowerCase().includes(action.payload.toLowerCase()) ||
            designs.substrate.toLowerCase().includes(action.payload.toLowerCase()) ||
            designs.base_color.toLowerCase().includes(action.payload.toLowerCase()) ||
            designs.top_color.toLowerCase().includes(action.payload.toLowerCase())
        );

      const searchedPrintList: DesignPropertiesType[] = state.filteredDesigns.filter(
        designs =>
          designs.filename.toLowerCase().includes(action.payload.toLowerCase()) ||
          designs.requested_by.toLowerCase().includes(action.payload.toLowerCase()) ||
          designs.status.toLowerCase().includes(action.payload.toLowerCase()) ||
          designs.substrate.toLowerCase().includes(action.payload.toLowerCase()) ||
          designs.base_color.toLowerCase().includes(action.payload.toLowerCase()) ||
          designs.top_color.toLowerCase().includes(action.payload.toLowerCase())
      );

      return {
        ...state,
        searchQuery: action.payload,
        pageNumber: 1,
        completedSearchedDesigns: completedSearchedPrintList,
        searchedDesigns: searchedPrintList,
      };
    }

    /**
     * This case statement is for the 'RESET' action type. It updates the state by
     * returning a new object that contains all properties of the current state,
     * but with the filteredDesigns and completedFilteredDesigns properties set to the original designs arrays.
     * This effectively resets any previous filtering that may have been applied.
     * @param {Object} state - The current state of the application
     * @returns {Object} - A new state object with the filteredDesigns and completedFilteredDesigns properties reset to the original designs arrays
     */
    case 'RESET': {
      return {
        ...state,
        searchQuery: '',
        selectedFilters: {},
        filteredDesigns: state.initialDesigns,
        searchedDesigns: state.initialDesigns,
        completedFilteredDesigns: state.initialCompletedDesigns,
        completedSearchedDesigns: state.initialCompletedDesigns,
      };
    }

    default:
      throw new Error('Unidentified reducer action type');
  }
};

const usePrintListContext = (initPrintListState: PrintListStateType) => {
  const [state, dispatch] = useReducer(printFlow, initPrintListState);

  const REDUCER_ACTIONS = useMemo(() => REDUCER_ACTION_TYPE, []);

  const {
    activeNavItem,
    sortOn,
    sortDirection,
    activeId,
    designFileJsonData,
    importFinished,
    importError,
    importErrorMessage,
    importHeader,
    importButtonText,
    printHeader,
    printMessage,
    printButtonText,
    printStep,
    printError,
    filteredDesigns,
    searchedDesigns,
    completedFilteredDesigns,
    completedSearchedDesigns,
    showCompleted,
    pageNumber,
    searchQuery,
    filters,
    selectedFilters,
    initialDesigns,
    initialCompletedDesigns,
  } = state;

  return {
    dispatch,
    REDUCER_ACTIONS,
    activeNavItem,
    sortOn,
    sortDirection,
    activeId,
    designFileJsonData,
    importFinished,
    importError,
    importErrorMessage,
    importHeader,
    importButtonText,
    printHeader,
    printMessage,
    printButtonText,
    printStep,
    printError,
    showCompleted,
    pageNumber,
    searchQuery,
    filters,
    selectedFilters,
    initialDesigns,
    filteredDesigns,
    searchedDesigns,
    initialCompletedDesigns,
    completedFilteredDesigns,
    completedSearchedDesigns,
  };
};

export type UsePrintListContextType = ReturnType<typeof usePrintListContext>;

const initPrintListContextState: UsePrintListContextType = {
  dispatch: () => {},
  REDUCER_ACTIONS: REDUCER_ACTION_TYPE,
  activeNavItem: '/',
  sortOn: 'date_time',
  sortDirection: false,
  activeId: 'new',
  designFileJsonData: {},
  importFinished: false,
  importError: false,
  importErrorMessage: '',
  importHeader: '',
  importButtonText: '',
  printHeader: '',
  printMessage: '',
  printButtonText: '',
  printStep: 1,
  printError: false,
  showCompleted: false,
  pageNumber: 1,
  searchQuery: '',
  filters: {},
  selectedFilters: {},
  initialDesigns: [],
  filteredDesigns: [],
  searchedDesigns: [],
  initialCompletedDesigns: [],
  completedFilteredDesigns: [],
  completedSearchedDesigns: [],
};

const PrintListContext = createContext<UsePrintListContextType>(initPrintListContextState);

type ChildrenType = { children?: ReactElement | ReactElement[] };

export function PrintListProvider({ children }: ChildrenType): ReactElement {
  return (
    <PrintListContext.Provider value={usePrintListContext(initPrintListState)}>
      {children}
    </PrintListContext.Provider>
  );
}

export default PrintListContext;
