/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable no-console */
import { Text, Button, SidePanel, Select, Icon, ButtonGroup, MultiValue } from '@nike/eds';
import { useEffect, useState } from 'react';
import usePrintList from '../../hooks/usePrintList';

type MultiValueType = {
  value: string;
  label: string;
};
function FilterButton() {
  const { dispatch, filters, selectedFilters } = usePrintList();
  const [visible, setVisible] = useState(false);
  const [topColors, setTopColors] = useState<MultiValue<MultiValueType>>();
  const [baseColors, setBaseColors] = useState<MultiValue<MultiValueType>>();
  const [substrates, setSubstrates] = useState<MultiValue<MultiValueType>>();

  function listToObjects(lst: string[]): MultiValueType[] {
    const filterOptions: MultiValueType[] = [];
    lst.map(str => filterOptions.push({ value: str, label: str }));
    return filterOptions;
  }

  function mapObjectsToStrings(objects: MultiValue<MultiValueType>): string[] {
    return objects.map(object => object.value.toString());
  }

  useEffect(() => {
    if (selectedFilters.base_color !== undefined) {
      setBaseColors(listToObjects(selectedFilters.base_color));
    }
    if (selectedFilters.top_color !== undefined) {
      setTopColors(listToObjects(selectedFilters.top_color));
    }
    if (selectedFilters.substrate !== undefined) {
      setSubstrates(listToObjects(selectedFilters.substrate));
    }
  }, [
    selectedFilters,
    selectedFilters.base_color,
    selectedFilters.substrate,
    selectedFilters.top_color,
  ]);

  function Content() {
    if (filters.base_color !== undefined) listToObjects(filters.base_color);
    return (
      <form id="demo-form" style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
        <div className="eds-flex eds-flex--direction-row eds-flex--justify-content-start">
          <Button
            variant="ghost"
            size="small"
            onClick={() => {
              dispatch({
                type: 'SET_SELECTED_FILTERS',
                payload: {
                  base_color: [],
                  top_color: [],
                  substrate: [],
                },
              });
            }}
          >
            Reset
          </Button>
        </div>
        <Text font="title-4">Color</Text>
        <div>
          <Select
            label="Base Color"
            isMulti
            value={baseColors}
            onChange={values => setBaseColors(values)}
            options={filters.base_color !== undefined ? listToObjects(filters.base_color) : []}
            id=""
            required="false"
          />
        </div>
        <div>
          <Select
            label="Secondary Color"
            isMulti
            value={topColors}
            onChange={values => {
              setTopColors(values);
            }}
            options={filters.top_color !== undefined ? listToObjects(filters.top_color) : []}
            id=""
            required="false"
          />
        </div>
        <hr
          style={{
            width: '100%',
          }}
        />
        <Text font="title-4">Substrate</Text>
        <div>
          <Select
            label="Type"
            isMulti
            value={substrates}
            onChange={values => {
              setSubstrates(values);
            }}
            options={filters.substrate !== undefined ? listToObjects(filters.substrate) : []}
            id=""
            required="false"
          />
        </div>
        <div className="eds-spacing--mb-48">
          <Select
            id=""
            label="Material"
            isMulti
            onChange={values => {}}
            options={[
              { value: '1', label: 'IP-300' },
              { value: '2', label: 'IP-400' },
              { value: '3', label: 'IP-500' },
              { value: '4', label: 'IP-600' },
            ]}
            required="false"
          />
        </div>
        <hr
          style={{
            width: '100%',
          }}
        />
        <div>
          <ButtonGroup>
            <Button
              onClick={() => {
                dispatch({
                  type: 'SET_SELECTED_FILTERS',
                  payload: {
                    top_color: topColors !== undefined ? mapObjectsToStrings(topColors) : [],
                    base_color: baseColors !== undefined ? mapObjectsToStrings(baseColors) : [],
                    substrate: substrates !== undefined ? mapObjectsToStrings(substrates) : [],
                  },
                });
              }}
              size="small"
            >
              Apply
            </Button>
            <Button
              onClick={() => {
                if (selectedFilters.base_color !== undefined) {
                  setBaseColors(listToObjects(selectedFilters.base_color));
                }
                if (selectedFilters.top_color !== undefined) {
                  setTopColors(listToObjects(selectedFilters.top_color));
                }
                if (selectedFilters.substrate !== undefined) {
                  setSubstrates(listToObjects(selectedFilters.substrate));
                }
                setVisible(false);
              }}
              size="small"
              variant="secondary"
            >
              Cancel
            </Button>
          </ButtonGroup>
        </div>
      </form>
    );
  }

  return (
    <div className="eds-flex eds-flex--direction-row eds-flex--justify-content-flex-end eds-flex--align-items-center eds-spacing--mr-48">
      <Button className="eds-spacing--mt-36" variant="ghost" onClick={() => setVisible(!visible)}>
        Show Filters <Icon name="Filter" />
      </Button>
      <SidePanel
        onDismiss={() => {
          setVisible(false);
          if (selectedFilters.base_color !== undefined) {
            setBaseColors(listToObjects(selectedFilters.base_color));
          }
          if (selectedFilters.top_color !== undefined) {
            setTopColors(listToObjects(selectedFilters.top_color));
          }
          if (selectedFilters.substrate !== undefined) {
            setSubstrates(listToObjects(selectedFilters.substrate));
          }
        }}
        isOpen={visible}
        headerSlot={<Text>Filters</Text>}
        hasScrim={false}
      >
        <Content />
      </SidePanel>
    </div>
  );
}

export default FilterButton;
