import { Icon, Text } from '@nike/eds';
import React from 'react';
import styles from './Loading.module.css';

function LoadingPage() {
  return (
    <div className={`${styles['loading-page']}`}>
      <Text font="display-2">Loading...</Text>
      <div className={`${styles['loading-icon']}`}>
        <Icon name="Sustainability" size="l" />
      </div>
    </div>
  );
}

export default LoadingPage;
