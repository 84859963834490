import { Button, IconButton, Text } from '@nike/eds';
import { useEffect, useState } from 'react';
import usePrintList from '../../hooks/usePrintList';
import styles from './SelectedFilterItems.module.css';

type MappedFilters = {
  list: 'base_color' | 'top_color' | 'substrate';
  value: string;
};

export function SelectedFilterItems() {
  const {
    dispatch,
    selectedFilters,
    showCompleted,
    searchedDesigns,
    completedSearchedDesigns,
    filteredDesigns,
    completedFilteredDesigns,
  } = usePrintList();
  const [filtersToShow, setFilterToShow] = useState<MappedFilters[]>([]);

  function mapStringsToObject(
    strings: string[],
    listName: 'base_color' | 'top_color' | 'substrate'
  ): MappedFilters[] {
    return strings.map(str => ({
      list: listName,
      value: str,
    }));
  }

  useEffect(() => {
    let baseColors: MappedFilters[] = [];
    let topColors: MappedFilters[] = [];
    let substrates: MappedFilters[] = [];
    if (selectedFilters.base_color !== undefined) {
      baseColors = mapStringsToObject(selectedFilters.base_color, 'base_color');
    }
    if (selectedFilters.top_color !== undefined) {
      topColors = mapStringsToObject(selectedFilters.top_color, 'top_color');
    }

    if (selectedFilters.substrate !== undefined) {
      substrates = mapStringsToObject(selectedFilters.substrate, 'substrate');
    }

    setFilterToShow([...baseColors, ...topColors, ...substrates]);
  }, [selectedFilters.base_color, selectedFilters.substrate, selectedFilters.top_color]);

  if (
    filtersToShow.length > 0 ||
    searchedDesigns.length < filteredDesigns.length ||
    completedSearchedDesigns.length < completedFilteredDesigns.length
  )
    return (
      <div className="eds-grid--col-full">
        <div className="eds-flex eds-flex--direction-row eds-flex--align-items-center">
          <div className="eds-flex--direction-column eds-spacing--mr-8">
            <Text font="body-3">{`${
              showCompleted ? completedSearchedDesigns.length : searchedDesigns.length
            } results`}</Text>
            {filtersToShow.length > 0 && (
              <Button
                variant="ghost"
                onClick={() => {
                  dispatch({
                    type: 'SET_SELECTED_FILTERS',
                    payload: {
                      base_color: [],
                      top_color: [],
                      substrate: [],
                    },
                  });
                }}
              >
                Clear All
              </Button>
            )}
          </div>
          {filtersToShow.length > 0 && <div className={`${styles['vertical-divider']}`} />}
          <div className="eds-flex eds-flex--direction-row eds-flex--align-items-center">
            {filtersToShow.map(filter => (
              <div
                className={`${styles['search-filter-item']} eds-flex eds-flex--direction-row eds-flex--align-items-center`}
              >
                <Text className="eds-spacing--mr-8" font="body-2">
                  {filter.value}
                </Text>
                <IconButton
                  variant="ghost"
                  icon="Close"
                  label=""
                  size="small"
                  onClick={() => {
                    selectedFilters[filter.list] = selectedFilters[filter.list]?.filter(
                      item => item !== filter.value
                    );

                    dispatch({
                      type: 'SET_SELECTED_FILTERS',
                      payload: selectedFilters,
                    });
                  }}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  return null;
}

export default SelectedFilterItems;
