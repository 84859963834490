/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-restricted-globals */
/* eslint-disable react/jsx-no-constructed-context-values */
import { Box, Icon, Table, TableCell, TableHeading, Text } from '@nike/eds';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { DesignPropertiesType, FiltersType } from '../../context/PrintListProvider';
import { environment } from '../../environments/environment';
import useHeader from '../../hooks/useHeader';
import usePrintList from '../../hooks/usePrintList';
import PrintButton from '../PrintButton/PrintButton';
import shoeImage from '../../assets/temp_shoe.png';

import styles from './PrintList.module.css';

type HeaderColumnType = {
  id:
    | 'filename'
    | 'base_color'
    | 'top_color'
    | 'gender'
    | 'size'
    | 'substrate'
    | 'requested_by'
    | 'date_time';
  // | 'print';
  headerName: string;
};

const getCurrentPage = (list: DesignPropertiesType[], pageNumber: number) =>
  list.slice((pageNumber - 1) * 7, pageNumber * 7);

export function PrintList() {
  const {
    dispatch,
    initialDesigns,
    initialCompletedDesigns,
    searchedDesigns,
    completedSearchedDesigns,
    showCompleted,
    pageNumber,
    sortOn,
    sortDirection,
  } = usePrintList();
  const { userEmail } = useHeader();

  const headers: HeaderColumnType[] = [
    {
      id: 'filename',
      headerName: 'Design Pack',
    },
    {
      id: 'base_color',
      headerName: 'Layer 1 Color',
    },
    {
      id: 'top_color',
      headerName: 'Layer 2 Color',
    },
    {
      id: 'gender',
      headerName: 'Gender',
    },
    {
      id: 'size',
      headerName: 'Size',
    },
    {
      id: 'substrate',
      headerName: 'Substrate',
    },
    {
      id: 'requested_by',
      headerName: 'Requested By',
    },
    {
      id: 'date_time',
      headerName: 'Date/Time',
    },
  ];

  const handleScan = async (id: string) => {
    const resp = await fetch(`${environment.serverUrl}/scan`, {
      method: 'POST',
      body: JSON.stringify({ id }),
    });

    const s = await resp.json();
    return s;
  };

  function handleHeaderClick(
    header:
      | 'filename'
      | 'base_color'
      | 'top_color'
      | 'gender'
      | 'size'
      | 'substrate'
      | 'requested_by'
      | 'date_time'
  ) {
    dispatch({ type: 'SORT', payload: { sortOn: header, direction: !sortDirection } });
  }

  /**
   * separateLists - separates a list of DesignPropertiesType objects into separate sets based on the keys
   * @param {DesignPropertiesType[]} list - List of DesignPropertiesType objects
   * @returns {FiltersType} - Object of separate sets based on keys of DesignPropertiesType
   */
  function separateLists(list: DesignPropertiesType[]) {
    const result = {} as Record<
      keyof DesignPropertiesType,
      Set<DesignPropertiesType[keyof DesignPropertiesType]>
    >;
    list.forEach(item => {
      Object.keys(item).forEach(key => {
        if (!result[key]) {
          result[key] = new Set<DesignPropertiesType[keyof DesignPropertiesType]>();
        }
        result[key].add(item[key] as DesignPropertiesType[keyof DesignPropertiesType]);
      });
    });
    return Object.fromEntries(
      Object.entries(result).map(([key, value]) => [key, [...value]])
    ) as FiltersType;
  }

  useEffect(() => {
    const filters = separateLists(showCompleted ? initialCompletedDesigns : initialDesigns);
    dispatch({ type: 'SET_FILTERS', payload: filters });
  }, [dispatch, initialCompletedDesigns, initialDesigns, showCompleted]);

  return (
    <div className={`${styles['scrollable-table']} eds-grid--col-full`}>
      <Table className={`${styles['print-list']} eds-spacing--mb-16`}>
        <thead>
          <tr className={`${styles['print-list-header-row']} ${styles['print-list-header']}`}>
            {headers.map(column => (
              <TableHeading
                key={column.id}
                onClick={e => {
                  handleHeaderClick(column.id);
                }}
              >
                <th
                  className={`${styles['print-list-header']} eds-flex eds-flex--align-items-center`}
                >
                  {column.headerName}
                  {sortOn === column.id ? (
                    <Icon
                      className="eds-spacing--ml-8"
                      name={sortDirection ? 'SortAscending' : 'SortDescending'}
                      size="m"
                    />
                  ) : (
                    <Box className="eds-spacing--ml-8" height={24} width={24} />
                  )}
                </th>
              </TableHeading>
            ))}
          </tr>
        </thead>

        <tbody>
          {getCurrentPage(
            showCompleted ? completedSearchedDesigns : searchedDesigns,
            pageNumber
          ).map((data: DesignPropertiesType, index) => (
            <tr key={`${data.id}-${index}`}>
              <TableCell hidden>{data.id}</TableCell>
              <TableCell>
                <img src={shoeImage} alt="shoe_image" />

                <div className="eds-flex eds-flex--direction-column">
                  <Text font="body-2">{data.filename}</Text>
                  {/* TODO: Fix this with real value from Design Pack */}
                  <Text className="eds-color--secondary" font="body-2">
                    {data.season}
                  </Text>
                </div>
              </TableCell>
              <TableCell>{data.base_color}</TableCell>
              <TableCell>{data.top_color}</TableCell>
              <TableCell>{data.gender}</TableCell>
              <TableCell>{data.size}</TableCell>
              <TableCell>
                <div className="eds-flex eds-flex--direction-column">
                  <Text font="body-2">{data.substrate}</Text>
                  {/* TODO: Fix this with real value from Design Pack, if it exists */}
                  <Text className="eds-color--secondary" font="body-2">
                    IP-003
                    {/* <TableCell>{data.material}</TableCell> */}
                  </Text>
                </div>
              </TableCell>
              <TableCell>
                <Link
                  to="#"
                  style={{ color: '-webkit-link' }}
                  onClick={e => {
                    window.location.href = `mailto:${userEmail}`;
                    e.preventDefault();
                  }}
                >
                  {data.requested_by}
                </Link>
              </TableCell>
              <TableCell>
                {new Date(data.requested_date).toLocaleString('en-US', {
                  timeZone: 'America/Los_Angeles',
                })}
              </TableCell>
              <TableCell>
                <PrintButton design={data} />
              </TableCell>
            </tr>
          ))}
          {Array(
            7 -
              getCurrentPage(showCompleted ? completedSearchedDesigns : searchedDesigns, pageNumber)
                .length
          )
            .fill(<tr className={`${styles['empty-row']}`} />)
            .map(emptyRow => emptyRow)}
        </tbody>
      </Table>
    </div>
  );
}

export default PrintList;
